import { createElement } from "react";
import { SOCIAL_MEDIA_OBJ } from "../utils/consts";
import profilePhoto from "../assets/images/homepageYoram.jpg";
import "../scrolldown.css";
function Homepage() {
  return (
    <section className="home" id="home">
      <div
        className="home_name_body"
        data-aos="slide-left"
        data-aos-delay="800"
      >
        <div className="home_socials_container">
          {SOCIAL_MEDIA_OBJ.map((content, i) => (
            <div key={i} className="home_social">
              <a href={content.link} target="_blank" rel="noreferrer">
                <div>{createElement(content.icon)}</div>
              </a>
            </div>
          ))}
        </div>
        <h1 className="home_name">
          Yoram <br />
          Izilov
        </h1>
      </div>
      <div className="home_details_body" data-aos="fade-down">
        <div className="home_image">
          <img
            src={profilePhoto}
            data-aos="slide-up"
            alt="..."
            className="image"
          />
        </div>
        <div className="home_content">
          <h2 className="home_details_title">Full-Stack Developer</h2>

          <div className="home_button_body">
            <a href={"#contact"}>
              <button className="home_button">Get In Touch</button>
            </a>
          </div>
          <div className="home_details">
            <p>
              Ambitious software developer, eager to learn and improve. <br /> A
              bit about myself – from early days I developed apps and games. For
              example in 2016 I created an app which helps citizens to find the
              quickest route to a shelter in case of red alert. This app was
              shown to Rav Aluf Gadi Eizenkot. I am a software developer looking
              for a full-stack position and eager to share my abilities.
            </p>
          </div>
        </div>
      </div>

      {/* scroll down with animation */}
      <div className="scroll_body" data-aos="fade-in" data-aos-delay="2000">
        <span className="vertical_scroll_line"></span>
        <span className="arrow_down"></span>
        scrolldown
      </div>
    </section>
  );
}

export default Homepage;
