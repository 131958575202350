import { createElement, useRef } from "react";
import { TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useFormControls } from "../hooks/useFormControls";
import { inputContactPageFieldValues, SOCIAL_MEDIA_OBJ } from "../utils/consts";
import { LoadingButton } from "@mui/lab";
import toast, { Toaster } from "react-hot-toast";

const Contact = () => {
  const form = useRef(null);

  const handleToast = (toastMessage: string, isSuccess: boolean) => {
    if (isSuccess) {
      toast.success(toastMessage);
    } else {
      toast.error(toastMessage);
    }
  };

  const { handleInputValue, handleFormSubmit, formIsValid, errors, isPending } =
    useFormControls({ handleToast });
  return (
    <section className="contact" id="contact">
      <div className="contact_body">
        <Toaster
          reverseOrder={false}
          position="bottom-right"
          toastOptions={{
            style: {
              borderRadius: "8px",
              background: "#333",
              color: "#fff",
            },
          }}
        />
        <h1 className="contact_title" data-aos="fade-down">
          Get In Touch:
        </h1>

        <div className="contact_content">
          <form ref={form} onSubmit={handleFormSubmit}>
            <div
              className="contact_form_body"
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
            >
              {inputContactPageFieldValues.map((inputFieldValue, index) => {
                return (
                  <TextField
                    style={{ margin: "0 0 10px 0 " }}
                    key={index}
                    onBlur={handleInputValue}
                    onChange={handleInputValue}
                    name={inputFieldValue.name}
                    label={inputFieldValue.label}
                    fullWidth
                    multiline={inputFieldValue.multiline ?? false}
                    rows={inputFieldValue.rows ?? 1}
                    autoComplete="none"
                    {...(errors[inputFieldValue.name] && {
                      error: true,
                      helperText: errors[inputFieldValue.name],
                    })}
                  />
                );
              })}
              <LoadingButton
                type="submit"
                endIcon={<SendIcon />}
                loading={isPending}
                loadingPosition="end"
                variant="contained"
                disabled={!formIsValid()}
                className="contact_send_button"
                color="primary"
              >
                Send
              </LoadingButton>
            </div>
          </form>
          <div className="contact_socials_body">
            {SOCIAL_MEDIA_OBJ.map((content, i) => (
              <div
                key={i}
                data-aos="fade-up"
                data-aos-delay={i * 350}
                className="contact_social_body"
              >
                <a href={content.link} target="_blank" rel="noreferrer">
                  <div>{createElement(content.icon)}</div>

                  {content.text}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
