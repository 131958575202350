import Divider from "@mui/material/Divider";
import { SKILLS_OBJ } from "../utils/consts";

const Skills = () => {
  return (
    <section className="skills" id="skills">
      <div className="skills_content">
        <h2 data-aos="fade-up">My Current Skills</h2>
        <h4 data-aos="fade-up">improving and aquiring more of:</h4>
        <br />
        <div className="skill_card">
          {SKILLS_OBJ.map((skill, i) => {
            return (
              <div
                key={i}
                data-aos="fade-up"
                data-aos-delay={i * 200}
                className="skill_card_body"
              >
                <div>
                  <img
                    src={skill.logo}
                    alt="skill logo loading..."
                    className="skill_card_logo"
                  />
                </div>
                <Divider orientation="vertical" flexItem className="divider" />
                <div>
                  <h4>{skill.name}</h4>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Skills;
