import { PROJECTS_OBJ } from "../utils/consts";
import { useEffect, useRef } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from "@mui/material";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import CodeIcon from "@mui/icons-material/Code";
import LinkIcon from "@mui/icons-material/Link";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
function Projects() {
  const projectsRef = useRef<HTMLDivElement>(null);

  var track = projectsRef.current as HTMLDivElement;
  useEffect(() => {
    //for when page refreshed
    track = projectsRef.current as HTMLDivElement;
  }, []);

  const handleOnDown = (event: any) => {
    track.dataset.mouseDownAt = event.clientX.toString();
  };

  const handleOnUp = () => {
    track.dataset.mouseDownAt = "0";
    track.dataset.prevPercentage = track.dataset.percentage || "0";
  };

  const handleOnMove = (e: any) => {
    if (track.dataset.mouseDownAt === "0") return;

    var mouseDelta =
      parseFloat(track.dataset.mouseDownAt as string) - e.clientX;
    var maxDelta = window.innerWidth / 2;

    var percentage = (mouseDelta / maxDelta) * -100;
    var nextPercentageUnconstrained =
      parseFloat(track.dataset.prevPercentage as string) + percentage;
    var nextPercentage = Math.max(
      Math.min(nextPercentageUnconstrained, 0),
      -100
    );

    track.dataset.percentage = nextPercentage.toString();

    track.animate(
      {
        transform: `translate(${nextPercentage}%, -50%)`,
      },
      { duration: 1200, fill: "forwards" }
    );

    const images = track.getElementsByClassName("project_image");
    for (
      let i = 0, image = images[i];
      i < images.length;
      i++, image = images[i]
    ) {
      image.animate(
        {
          objectPosition: `${100 + nextPercentage}% center`,
        },
        { duration: 1200, fill: "forwards" }
      );
    }
  };

  return (
    <section className="projects" id="projects">
      <div
        className="project_title_body"
        data-aos="fade-right"
        data-aos-delay="300"
      >
        <h1 className="projects_title">MY PROJECTS</h1>
      </div>

      {/* mobile design */}
      <div
        className="projects_layout_mobile"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        <Swiper
          loop={true}
          pagination={{
            clickable: true,
          }}
          spaceBetween={20}
          modules={[Pagination]}
          className="projects_swiper_body"
        >
          {PROJECTS_OBJ.map((project, i) => (
            <SwiperSlide key={i} className="swiper_slide_body">
              <img
                src={project.image}
                alt="Loading..."
                className="swiper_image"
              />
              <div className="swiper_slide_content">
                <h3 className="project_swiper_name">{project.name}</h3>
                <div className="project_button_group">
                  <a href={project.linkToCode} target="_blank" rel="noreferrer">
                    <CodeIcon
                      fontSize="small"
                      className="project_button"
                    ></CodeIcon>
                  </a>
                  {project.websiteLink && (
                    <a
                      href={project.websiteLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LinkIcon
                        fontSize="small"
                        className="project_button"
                      ></LinkIcon>
                    </a>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* desktop design */}
      <div className="projects_layout" data-aos="fade-up" data-aos-delay="600">
        <div
          ref={projectsRef}
          id="projects-track"
          data-mouse-down-at="0"
          data-prev-percentage="0"
          onMouseUp={handleOnUp}
          onMouseDown={handleOnDown}
          onMouseMove={handleOnMove}
        >
          {PROJECTS_OBJ.map((project, i) => {
            return (
              <Card key={i} className="project_card">
                <CardHeader title={project.name} className="project_name" />
                <CardMedia
                  id={project.id}
                  component="img"
                  image={project.image}
                  alt="loading"
                  className="project_image"
                  draggable="false"
                />
                <CardContent className="project_disc">
                  <Typography variant="body2" color="text.secondary">
                    {project.discription}
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  <div className="project_button_group">
                    <a
                      href={project.linkToCode}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <CodeIcon className="project_button"></CodeIcon>
                    </a>
                    {project.websiteLink && (
                      <a
                        href={project.websiteLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LinkIcon className="project_button"></LinkIcon>
                      </a>
                    )}
                  </div>
                </CardActions>
              </Card>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Projects;
