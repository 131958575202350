import { useEffect } from "react";
import Homepage from "./pages/Homepage";
import Projects from "./pages/Projects";
import Skills from "./pages/Skills";
import Contact from "./pages/Contact";
import Aos from "aos";
import "aos/dist/aos.css";
import Navbar from "./components/Navbar";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1500,
      offset: 100,
      disable: "mobile",
    });
  }, []);
  return (
    <div style={{ width: "100vw" }}>
      {/* <Navbar /> decided at the end to not inclide navbar*/}
      <Homepage />
      <Skills />
      <Projects />
      <Contact />
    </div>
  );
}

export default App;
