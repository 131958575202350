import angular from "../assets/images/skills/angular.png";
import react from "../assets/images/skills/react.png";
import csharp from "../assets/images/skills/csharp.png";
import html from "../assets/images/skills/html.png";
import java from "../assets/images/skills/java.png";
import mongodb from "../assets/images/skills/mongodb.png";
import redis from "../assets/images/skills/redis.png";
import mysql from "../assets/images/skills/mysql.png";
import nodejs from "../assets/images/skills/nodeJS.png";
import typescript from "../assets/images/skills/typescript.png";
import css from "../assets/images/skills/css.png";
import sass from "../assets/images/skills/sass.png";
import tailwind from "../assets/images/skills/tailwind.png";
import { Skill } from "./interfaces";

import blogsImage from "../assets/images/projects/blogs.png";
import portfolioReactImage from "../assets/images/projects/reactPortfolio.png";
import portfolioAngularImage from "../assets/images/projects/portfolioAngularSite.png";
import shopImage from "../assets/images/projects/shopSite.png";
import humanTypeImage from "../assets/images/projects/humantypeSite.png";

import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";

export const navPagesObj = [
  {
    link: "#home",
    icon: LinkedInIcon,
  },
  {
    link: "#skills",
    icon: LinkedInIcon,
  },
  {
    link: "#projects",
    icon: LinkedInIcon,
  },
  {
    link: "#contact",
    icon: LinkedInIcon,
  },
];

export const PROJECTS_OBJ = [
  {
    id: "1",
    image: shopImage,
    name: "Shop",
    tools: [angular, html, css, nodejs, redis, mysql],
    discription:
      "Generic shop that implemented redis for faster reloads and quicker response times from the server.",
    linkToCode: "https://github.com/NerdFromHell/shop",
  },
  {
    id: "2",
    image: portfolioAngularImage,
    name: "Portoflio - angular",
    tools: [angular, html, sass, nodejs],
    discription: "My first porfolio website.",
    linkToCode: "https://github.com/NerdFromHell/Portfolio",
    websiteLink: "",
  },
  {
    id: "3",
    image: humanTypeImage,
    name: "HumanType",
    tools: [angular, html, sass, nodejs],
    discription:
      "Fun little side project based on the popular typing website MonkeyType.",
    linkToCode: "https://github.com/NerdFromHell/human-type",
    websiteLink: "https://humantype-2c2fa.web.app/",
  },
  {
    id: "4",
    image: portfolioReactImage,
    name: "Portfolio - react",
    tools: [react, html, css, nodejs],
    discription:
      "My improved version of my angular portfolio made with react and added lively animations with aos.",
    linkToCode: "https://github.com/NerdFromHell/Portfolio-React",
    websiteLink: "https://yoram-izilov.com/",
  },
  {
    id: "5",
    image: blogsImage,
    name: "BLOGS!",
    tools: [react, nodejs, html, tailwind, mongodb],
    discription: "Simple blog site with React and MongoDB.",
    linkToCode: "https://github.com/NerdFromHell/BLOGS-",
  },
];

export const SOCIAL_MEDIA_OBJ = [
  {
    text: "izilovy@gmail.com",
    icon: EmailIcon,
    link: "mailto:izilovy@gmail.com",
  },
  {
    text: "GitHub",
    icon: GitHubIcon,
    link: "https://github.com/NerdFromHell",
  },
  {
    text: "LinkedIn",
    icon: LinkedInIcon,
    link: "https://il.linkedin.com/in/yoram-izilov",
  },
];
export const SKILLS_OBJ: Array<Skill> = [
  {
    name: "React",
    logo: react,
  },
  {
    name: "Angular",
    logo: angular,
  },
  {
    name: "C#",
    logo: csharp,
  },
  {
    name: "Node JS",
    logo: nodejs,
  },
  {
    name: "HTML5",
    logo: html,
  },
  {
    name: "JAVA",
    logo: java,
  },
  {
    name: "MongoDB",
    logo: mongodb,
  },
  {
    name: "Redis",
    logo: redis,
  },
  {
    name: "MySQL",
    logo: mysql,
  },
  {
    name: "TypeScript",
    logo: typescript,
  },
  {
    name: "CSS",
    logo: css,
  },
  {
    name: "Sass",
    logo: sass,
  },
  {
    name: "Tailwind",
    logo: tailwind,
  },
];

export const inputContactPageFieldValues = [
  {
    name: "user_name",
    label: "Full Name",
    id: "my-name",
  },
  {
    name: "user_email",
    label: "Email",
    id: "my-email",
  },
  {
    name: "message",
    label: "Message",
    id: "my-message",
    multiline: true,
    rows: 10,
  },
];
