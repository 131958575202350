import { useState } from "react";
import emailjs from "@emailjs/browser";

const initialFormValues = {
  user_name: "",
  user_email: "",
  message: "",
  formSubmitted: false,
  success: false,
};

export const useFormControls = ({ handleToast }: any) => {
  // Updates the values of the form while the user typing
  const [values, setValues] = useState(initialFormValues);
  // "errors" is used to check the form for errors
  const [errors, setErrors] = useState({} as any);
  // make the button disabled while sending email
  const [isPending, setIsPending] = useState<boolean>(false);

  const validate: any = (fieldValues = values) => {
    let temp: any = { ...errors };

    if ("user_name" in fieldValues)
      temp.user_name = fieldValues.user_name ? "" : "This field is required.";

    if ("user_email" in fieldValues) {
      temp.user_email = fieldValues.user_email ? "" : "This field is required.";

      if (fieldValues.user_email)
        temp.user_email = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(
          fieldValues.user_email
        )
          ? ""
          : "Email is not valid.";
    }

    if ("message" in fieldValues)
      temp.message = fieldValues.message ? "" : "This field is required.";

    setErrors({
      ...temp,
    });
  };

  const handleInputValue = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues.user_name &&
      fieldValues.user_email &&
      fieldValues.message &&
      Object.values(errors).every((x) => x === "");

    return isValid;
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    setIsPending(true);
    if (formIsValid()) {
      emailjs
        .send(
          "service_rwymg2e",
          "template_omz7qv2",
          values,
          "8vpWYfaAFvPHQzgPZ"
        )
        .then(
          (result) => {
            handleToast("Email sent Successfully", true);
            setIsPending(false);
          },
          (error) => {
            setIsPending(false);
            handleToast(error.text, false);
          }
        );
    }
  };

  return {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    isPending,
  };
};
